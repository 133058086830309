import { createSlice } from '@reduxjs/toolkit';

import { TPopupMode } from './types/modal-reducer.types';

export interface IModalsState {
  popupMode: TPopupMode;
}

const initialState: IModalsState = {
  popupMode: 'disabled',
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    closePopupAction: (state) => {
      state.popupMode = 'disabled';
    },
    openEnable2FAPopupAction: (state) => {
      state.popupMode = 'enable-2fa';
    },
    openDepositBtcPopupAction: (state) => {
      state.popupMode = 'deposit-btc';
    },
    openWithdrawBtcPopupAction: (state) => {
      state.popupMode = 'withdraw-btc';
    },
    openDepositElectricityPopupAction: (state) => {
      state.popupMode = 'deposit-electricity';
    },
    openChangeWalletPopupAction: (state) => {
      state.popupMode = 'change-wallet';
    },
    openAdditionalTermsPopupAction: (state) => {
      state.popupMode = 'additional-terms';
    },
    openElectricityCashbackTermsPopupAction: (state) => {
      state.popupMode = 'electricity-cashback-terms';
    },
  },
});

export const {
  closePopupAction,
  openEnable2FAPopupAction,
  openDepositBtcPopupAction,
  openWithdrawBtcPopupAction,
  openDepositElectricityPopupAction,
  openChangeWalletPopupAction,
  openAdditionalTermsPopupAction,
  openElectricityCashbackTermsPopupAction,
} = modalsSlice.actions;
export const modalsReducer = modalsSlice.reducer;
